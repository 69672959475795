<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
<template>
  <div
    v-click-outside="outsideCollapse"
    class="airport-picker no-tap-highlight leading-relative"
    @focusout="focusHandler"
  >
    <slot :isExpanded="isExpanded" :toggle="toggle" />
    <div
      ref="modal"
      class="airport-picker__modal flex flex-col md:max-w-xs bg-white md:rounded-lg border-athens border shadow-dropdown z-50 fixed inset-0 top-auto"
      :class="{ hidden: !isExpanded }"
    >
      <div v-show="isExpanded" class="h-full flex flex-col">
        <div
          class="airport-picker__modal__caret top-0 absolute transform rotate-45 -translate-y-1/2 border-gray-200 -z-10"
          :class="{
            'border-white border-0': countries.length < 1,
            'bg-silver-100 border': countries.length > 1,
          }"
        />
        <div
          v-if="countries.length > 1"
          class="airport-picker__modal__header md:bg-silver-100 border-b md:border-0 rounded-t-lg md:py-2.5 flex flex-col min-h-max shrink-0 flex-shrink-0"
        >
          <div
            class="md:hidden relative h-12 w-full bg-white place-content-end flex min-h-max"
          >
            <div
              role="button"
              class="inline-block relative my-auto right-2 z-10 w-5 h-5 p-5 ml-auto"
              tabindex="0"
              @click.stop.passive="collapse()"
              @keypress.stop.passive="collapse()"
            >
              <div
                class="absolute inset-y-1/2 inset-x-3 h-0.5 bg-black-alt-300 rounded origin-center transform rotate-45"
              />
              <div
                class="absolute inset-y-1/2 inset-x-3 h-0.5 bg-black-alt-300 rounded origin-center transform -rotate-45"
              />
            </div>
          </div>
          <div v-show="airports.length > 7" class="md:hidden block">
            <SearchInput
              :expression="expressionToHandle"
              :border-y-only="true"
              :placeholder="$i18n('general.search')"
              @expressionChanged="handleExpressionChange"
            />
          </div>
          <div v-if="countries.length > 1" class="flex place-items-center">
            <div
              role="button"
              tabindex="0"
              class="hover:text-orange-500 flex-shrink-0 box-content md:py-3 pb-4 pt-3 pl-6 pr-8 cursor-pointer"
              @keypress.stop.passive="navigateLeft"
              @click.stop.passive="navigateLeft"
            >
              <CaretRight
                class="transform rotate-180"
                :class="!isLeftScrollable ? 'text-gray-400' : ''"
              />
            </div>
            <div
              ref="countries"
              class="airport-picker__modal__header__countries w-full md:mb-0 mb-1 flex overflow-x-scroll overscroll-contain"
              @scroll.stop.passive="updateScrollables"
            >
              <template v-for="(country, index) in countries">
                <div v-if="!index" :key="`modal.${country}`">
                  <div
                    ref="country"
                    :key="index - 1"
                    :data-country="null"
                    :class="{
                      'text-orange-500':
                        !filteringCountry || expressionToHandle,
                    }"
                    tabindex="0"
                    class="airport-picker__modal__header__countries__country font-bold cursor-pointer py-2 px-3 whitespace-nowrap overflow-hidden flex-shrink-0"
                    @keydown.enter.passive="filterByCountry($event, null)"
                    @click.stop.passive="filterByCountry($event, null)"
                  >
                    {{ $i18n('general.all-countries') }}
                  </div>
                </div>
                <div
                  v-if="countries.length > 1"
                  ref="country"
                  :key="country"
                  :data-country="country"
                  class="airport-picker__modal__header__countries__country font-bold cursor-pointer py-2 px-3 whitespace-nowrap overflow-hidden flex-shrink-0"
                  tabindex="0"
                  :class="{
                    'text-orange-500':
                      filteringCountry === country &&
                      (!expressionToHandle || expressionToHandle === ''),
                  }"
                  @keydown.enter.passive="filterByCountry($event, country)"
                  @click.stop.passive="filterByCountry($event, country)"
                >
                  {{ country }}
                </div>
              </template>
            </div>
            <div
              class="md:hover:text-orange-500 flex-shrink-0 box-content md:py-3 pb-4 pt-3 pl-6 pr-8 cursor-pointer"
              @click.stop.passive="navigateRight"
            >
              <CaretRight :class="!isLeftScrollable ? 'text-gray-400' : ''" />
            </div>
          </div>
        </div>
        <div
          ref="airportScroller"
          :class="showShadow ? 'airport-picker__modal__content-shadow' : ''"
          class="airport-picker__modal__content -mb-10 md:mb-0 relative overscroll-contain overflow-y-scroll md:overflow-hidden flex-grow md:flex-grow-0 rounded"
        >
          <div
            class="airport-picker__modal__content__airports md:max-h-80 md:overflow-y-scroll px-6 md:overscroll-contain md:h-auto w-full"
            :class="showShadow ? 'md:pb-3' : 'md:py-3'"
          >
            <div v-show="filteredCountries && filteredCountries.length">
              <div
                v-for="country in filteredCountries"
                :key="`filtered.${country}`"
                class="airport-picker__modal__content__airports__country"
              >
                <div>
                  <div
                    v-if="countries.length > 1"
                    class="py-2 font-bold sticky -top-px bg-white"
                  >
                    {{ country }}
                  </div>
                  <div class="space-y-2">
                    <div
                      v-for="airport in getAirportsOfCountry(country)"
                      ref="airport"
                      :key="`country.${airport.id}`"
                      tabindex="0"
                      :data-id="airport.id"
                      class="md:hover:text-orange-500 cursor-pointer overflow-x-hidden overflow-ellipsis max-w-min whitespace-nowrap"
                      :class="{
                        'text-orange-500':
                          preselectAirport &&
                          preselectAirport.id === airport.id,
                      }"
                      @keydown.enter.passive="selectAirport(airport)"
                      @click.stop.passive="selectAirport(airport)"
                    >
                      {{ airport.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="!(filteredCountries && filteredCountries.length)">
              <div class="mt-3">
                {{ $i18n('customer.no-results') }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="airport-picker__modal__content__gradient block md:hidden h-20"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import { createPopper } from '@popperjs/core';
import CaretRight from '~/components/icons/IconCaretRight.vue';
import SearchInput from '~/components/header/SearchInput.vue';
import { has, assign } from 'lodash';

export default {
  directives: {
    clickOutside: ClickOutside,
  },
  components: {
    CaretRight,
    SearchInput,
  },
  props: {
    preselectAirport: {
      type: Object,
      default: () => undefined,
      required: false,
    },
    expression: {
      type: String,
      required: false,
      default: () => undefined,
    },
  },
  data() {
    return {
      isExpanded: false,
      popperInstance: undefined,
      filteringCountry: undefined,
      countries: [],
      airports: this.$store.state.airports,
      isLeftScrollable: false,
      isRightScrollable: false,
      calcTimeoutHandle: undefined,
      internalExpression: undefined,
    };
  },
  computed: {
    expressionToHandle() {
      if (process.server) {
        return undefined;
      }
      if (!this.$isMobile) {
        return this.expression;
      }
      if (this.$isMobile) {
        return this.internalExpression;
      }
      return this.internalExpression;
    },
    language() {
      return this.$store.state.language;
    },
    airportsSorted() {
      let cities = [];

      if (this.isSearchByCityNameVariant) {
        cities = [
          {
            city: 'London',
            airports: [
              'Heathrow',
              'Luton',
              'Stansted',
              'Gatwick',
              'London City',
              'Southend',
            ],
          },
          {
            city: 'Paris',
            airports: ['Orly', 'Charles De Gaulle', 'Beauvais'],
          },
          {
            city: 'Brussels',
            airports: ['Chareloi', 'Zaventem'],
          },
          {
            city: 'Milan',
            airports: ['Linate', 'Malpensa'],
          },
          {
            city: 'Rome',
            airports: ['Fiumicino', 'Ciampino'],
          },
          {
            city: 'Frankfurt',
            airports: ['Frankfurt', 'Frankfurt-Hahn'],
          },
          {
            city: 'Venice',
            airports: ['Marco Polo', 'Treviso'],
          },
          {
            city: 'Tenerife',
            airports: ['Tenerife Airport North', 'Tenerife Airport South'],
          },
          {
            city: 'Düsseldorf',
            airports: ['International', 'Weeze'],
          },
          {
            city: 'Barcelona',
            airports: ['El-Prat', 'Girona', 'Reus'],
          },
        ];
      }

      const obj = {};
      let airports = assign([], this.airports);
      if (this.expressionToHandle) {
        airports = airports.filter((airport) => {
          const searchParams = [
            airport.name,
            airport.iata_code,
            airport.address.city,
            airport.devtitle,
            airport.internationalAirportName,
            airport.slug,
          ];
          const expr = this.expressionToHandle.toLocaleLowerCase();

          if (this.isSearchByCityNameVariant) {
            if (
              airport.internationalAirportName.toLowerCase().includes('brussel')
            ) {
              searchParams.push('Brussels');
              searchParams.push('Brussel');
            }

            cities.forEach((c) => {
              c.airports.forEach((ca) => {
                // Check if the current airport exists in the airports array of the city
                if (
                  airport.name.includes(ca)
                  || airport.internationalAirportName.includes(ca)
                ) {
                  // If it exists then push the name of the city as a search parameter
                  searchParams.push(c.city);
                }
              });
            });
          }

          const search = searchParams
            .filter(Boolean)
            .map((value) => value.toLowerCase());
          return search.some((value) => value.includes(expr));
        });
      }
      if (!this.language.is_minimal_product) {
        airports = airports.filter((airport) => airport.parking_count);
      }
      airports.forEach((airport) => {
        if (!has(obj, airport.country.name)) {
          obj[airport.country.name] = [];
        }
        obj[airport.country.name].push(airport);
      });

      return obj;
    },
    filteredCountries() {
      const countries = Object.keys(this.airportsSorted).filter(
        (c) => !this.filteringCountry
          || c === this.filteringCountry
          || this.expressionToHandle,
      );
      return this.sortCountries(countries);
    },
    showShadow() {
      if (!this.filteredCountries || this.filteredCountries.length === 0) {
        return false;
      }
      for (let index = 0; index < this.filteredCountries.length; index++) {
        const country = this.filteredCountries[index];
        if (this.getAirportsOfCountry(country).length > 2) {
          return true;
        }
      }
      return false;
    },
    isSearchByCityNameVariant() {
      return (
        this.$store?.getters?.experimentVariant('SearchByCityName') === 'b'
      );
    },
  },
  created() {
    this.countries = Object.keys(this.airportsSorted);
    this.sortCountries(this.countries);
  },
  mounted() {
    this.$store.dispatch('experimentVariant', { name: 'SearchByCityName' });
    if (!this.$isMobile) {
      this.popperInstance = createPopper(this.$el, this.$refs.modal, {
        placement: 'bottom-start',
        strategy: 'fixed',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 12],
            },
          },
        ],
      });
    }
  },
  methods: {
    /**
     * @param {string} name
     * @return {Promise<HTMLElement>}
     */
    getRef(name) {
      return new Promise((resolve) => {
        if (this.$refs[name]) {
          resolve(this.$refs[name]);
          return;
        }

        const timeout = setInterval(() => {
          if (this.$refs[name]) {
            clearInterval(timeout);
            resolve(this.$refs[name]);
          }
        }, 50);
      });
    },
    sortCountries(countries) {
      return countries.sort((a, b) => {
        const lengthA = this.airportsSorted[a].length;
        const lengthB = this.airportsSorted[b].length;
        const nativeName = this.language.native_name;
        if (a === nativeName) {
          return Number.MIN_SAFE_INTEGER;
        }
        if (b === nativeName) {
          return Number.MAX_SAFE_INTEGER;
        }
        if (lengthA === lengthB) {
          return a.localeCompare(b);
        }
        return lengthA < lengthB;
      });
    },
    getAirportsOfCountry(country) {
      return this.airportsSorted[country];
    },
    handleExpressionChange(value) {
      this.internalExpression = value;
    },
    navigateLeft() {
      if (!this.isLeftScrollable) {
        return;
      }
      const mostCentered = this.findCurrentMostCenteredCountry();
      if (mostCentered === undefined || mostCentered < 1) {
        return;
      }

      this.$nextTick(() => {
        this.$refs.country[mostCentered - 1].scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      });
    },
    findCurrentMostCenteredCountry() {
      let minDist = Number.MAX_SAFE_INTEGER;
      let minElIndex;
      const rectParent = this.$refs.countries.getBoundingClientRect();
      this.$refs.country.forEach((c, index) => {
        const rect = c.getBoundingClientRect();
        const refDist = Math.abs(
          rectParent.x + rectParent.width / 2 - (rect.x + rect.width / 2),
        );
        if (refDist < minDist) {
          minDist = refDist;
          minElIndex = index;
        }
      });
      return minElIndex;
    },
    navigateRight() {
      if (!this.isRightScrollable) {
        return;
      }
      const mostCentered = this.findCurrentMostCenteredCountry();
      if (
        mostCentered === undefined
        || mostCentered >= this.$refs.country.length - 1
      ) {
        return;
      }

      this.$nextTick(() => {
        this.$refs.country[mostCentered + 1].scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      });
    },
    updateScrollables() {
      clearTimeout(this.calcTimeoutHandle);
      this.calcTimeoutHandle = setTimeout(() => {
        if (!this.$refs.countries) {
          return;
        }
        this.isLeftScrollable = this.$refs.countries.scrollLeft > 0;
        const { lastChild } = this.$refs.countries;
        if (!this.$refs.countries || !lastChild) {
          this.isRightScrollable = false;
        } else if (lastChild.getBoundingClientRect) {
          const rectChild = lastChild.getBoundingClientRect();
          const rect = this.$refs.countries.getBoundingClientRect();
          this.isRightScrollable = rectChild.x + rectChild.width * 0.95 > rect.x + rect.width;
        }
      }, 50);
    },
    toggle() {
      if (this.isExpanded) {
        this.collapse();
      } else {
        this.expand();
      }
    },
    outsideCollapse() {
      if (this.isExpanded && !this.$isMobile) {
        this.collapse();
      }
    },
    collapse() {
      setTimeout(() => {
        if (window.location.hash === '#picker') {
          window.history.back();
        }
      });
      if (!this.isExpanded) {
        return;
      }
      this.clearFiltering();
      this.isExpanded = false;
      this.$emit('collapsed', true);
      if (this.$isMobile) {
        this.$store.dispatch('highlight/hide');
      }
    },
    expand() {
      if (this.$isMobile) {
        window.location.hash = 'picker';
      }
      this.clearFiltering();
      this.isExpanded = true;
      this.filteringCountry = undefined;
      this.selectAirport(this.preselectAirport, false);

      this.$nextTick(async() => {
        const airportScroller$ = await this.getRef('airportScroller');

        airportScroller$.classList.add('flex-shrink-0', 'shrink-0');
        this.$nextTick(() => airportScroller$.classList.remove('flex-shrink-0', 'shrink-0'));

        if (this.$refs.countries) {
          this.$refs.countries.scrollLeft = 0;
        }
      });
      this.updateScrollables();
      if (this.$isMobile) {
        this.$store.commit('highlight/show', this.$refs.modal);
      }
      this.$emit('expanded');
    },
    selectAirport(airport, emitAndCollapse = true) {
      if (!airport || !airport.id) {
        return;
      }
      this.$nextTick(() => {
        if (this.$refs.airport && Array.isArray(this.$refs.airport)) {
          const airportEl = this.$refs.airport?.filter(
            (f) => +(f.dataset.id || -1) === airport.id,
          );
          if (airportEl && airportEl.length) {
            airportEl[0].scrollIntoView({
              behavior: 'auto',
              block: 'center',
            });
          }
        }
      });
      if (emitAndCollapse) {
        this.collapse();
        this.$emit('airportSelected', airport);
        this.$store.commit('bucket/update', {
          key: 'airport',
          value: (({
            id, name, slug, commonName, devtitle,
          }) => ({
            id,
            name,
            slug,
            commonName,
            devtitle,
          }))(airport),
        });
      }
    },
    filterByCountry(event, country) {
      this.clearFiltering();
      this.filteringCountry = country;
      if (event && event.target) {
        event.target.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
      this.updateScrollables();
    },
    clearFiltering() {
      this.internalExpression = undefined;
      this.$emit('clearExpression');
    },
    focusHandler(e) {
      if (e.relatedTarget) {
        if (
          e.relatedTarget.localName === 'a'
          || e.relatedTarget.localName === 'button'
          || e.relatedTarget.className.includes('date-time-picker')
        ) {
          this.outsideCollapse();
        }
      }
    },
  },
};
</script>

<style lang="scss">
.airport-picker {
  &__modal {
    @apply h-full md:h-auto;
    &__caret {
      width: 12px;
      height: 12px;
      left: 5%;
    }
    &__header {
      &__countries {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          @apply hidden;
        }
        &__country {
          &:first-child {
            @apply pl-0;
          }
          &:last-child {
            @apply pr-0;
          }
        }
      }
    }
    &__content-shadow {
      &::after {
        @apply hidden md:block absolute z-20 pt-10 pointer-events-none bottom-0 w-full;
        content: '';
        background: linear-gradient(
          180deg,
          hsla(0, 0%, 100%, 0) 0,
          hsla(0, 0%, 96.1%, 0.92941176470588235) 65%,
          hsla(0, 0%, 96.1%, 0.9019607843137255)
        );
      }
    }
    &__content {
      @supports (-webkit-overflow-scrolling: touch) {
        display: flex;
        align-content: stretch;
      }

      &__airports {
        &__country {
          flex: 0 0 100%;
          @apply space-y-3 py-3;
        }
        @media (min-width: 576px) {
          height: fit-content;
        }
        @media (max-width: 575px) {
          & > div:last-child {
            @apply pb-10;
          }
        }
        scrollbar-width: thin;
        &::-webkit-scrollbar {
          width: 6px;
          @apply bg-white;
        }
        &::-webkit-scrollbar-thumb {
          @apply bg-black-alt-300 rounded-md;
        }
      }
      &__gradient {
        @apply sticky -bottom-4 w-full -mb-px;
        background: linear-gradient(
          180deg,
          hsla(0, 0%, 100%, 0) 0,
          hsla(0, 0%, 96.1%, 0.92941176470588235) 65%,
          hsla(0, 0%, 96.1%, 0.9019607843137255)
        );
      }
    }
  }
}
</style>
